import Image from "next/image";
import styles from './CitiLeadForm.module.css';
import { Lead } from './';
import { ModuleType } from '../../../lib/constants';
import whatStyle from '../../../public/assets/images/whatStyle.jpg';
import { staticLoader } from "../../../lib/helpers/imageLoader";

export function DesignIdeaLeadForm(){
    return (
        <div className={styles.whatsYourStyle}>
            <div className="mb46">
                <h2 className="font26 text222 mb12">What&apos;s Your Style?</h2>
                <p className="font16">Discover your decor flair with guided assistance from experts.</p>
            </div>
            <div className={`${styles.differenceStyle}`}>
                <div className={`${styles.beforeAfterImg}`}>
                    <figure>
                        <Image
                            height={529}
                            width={700}
                            src={whatStyle}
                            loader={staticLoader}                          
                            alt='Update Home Interiors with Latest Designs'
                            title='Update Your Home Space with Latest Interior Designs'
                            className="imgFluid"
                             />
                    </figure>
                </div>
                <div className={styles.citiForm+` bgPink`}>
                    <div className={styles.enquiryForm+` bgPink`}>
                        <div className={styles.formBox+` bgWhite`}>	
                            <Lead ModuleType={ModuleType.INTERIOR} Title="Meet the Designer" ButtonText="Book Free Consultation" />
                        </div>
                    </div>
                </div>                    
            </div>
        </div>
    );
}