import styles from './HomeBanner.module.css';
import Image from 'next/image';
import { ModuleType } from '../../lib/constants';
import { Lead } from '../shared/lead';
import noCostEmi from '../../public/assets/images/noCostEmiNew.svg';
import herowebbanner from '../../public/assets/images/hero-new-web-banner-v1.webp';
import { staticLoader } from '../../lib/helpers/imageLoader';
import { useState } from 'react';


export default function IndiaBannerWeb() {
	const [isPopUp, togglePopUp] = useState(false);
	const [isThanks, setThanks] = useState(false)
	const popUpHandler = () => {
		togglePopUp(!isPopUp);
	}

	return (
		<div className={`${styles.bannerSection} homeBannerCarousel`}>
			{isPopUp ? <div className="overlay">
				<div className="regFormBox popup">
					{!isThanks && <div className='closeModal' onClick={popUpHandler}>
						<div className='leadFormClose'></div>
					</div>
					}
					<Lead ModuleType={ModuleType.INTERIOR} ButtonText="Book Free Consultation" Title="Meet the Designer" popUpHandler={popUpHandler} setThanks={setThanks} />
				</div>
			</div> : null}
			<div className={styles.homeIndiaMainbanner} >
				<div className={styles.bannerDetails} >
					<div className={styles.mobWidth}>
						<span className={styles.mainHeading}>Interior Design that Speaks of You</span>
						<div className={styles.subHeading}>From foundation to furnishings, we style your home like our own.</div>
						<div className={styles.bannerCtaButton}>
							<button className={`primaryBtn ${styles.mainPrimaryBtn}`} onClick={popUpHandler}> Book Free Consultation </button>
						</div>
					</div>
					<div className={styles.noCostEmi}>
						<Image
							quality='100'
							src={noCostEmi}
							alt="No Cost Emi"
							className="imgFluid"
						/>
					</div>
				</div>
				<div className={`${styles.bannerImage}`}>
					<Image priority src={herowebbanner} loader={staticLoader} quality={100} className={`${styles.bannerImg} img-responsive`} width={1500} height={640} alt="home-banner" />
				</div>
			</div>
		</div>
	);
}