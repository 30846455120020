import dynamic from 'next/dynamic';
import Image from "next/image";
import styles from './CitiLeadForm.module.css';
import { useGlobalContext } from '../../../lib/context';
import { CountryCode, ModuleType} from '../../../lib/constants';
import indiaMap from '../../../public/assets/images/mapCity.svg';
import uaeMap from '../../../public/assets/images/uaeMapCity.svg';
const Lead = dynamic(() => import('./').then((mod) => mod.Lead),{
      suspense:true,
  })

export function CitiLeadForm(){
    const { props:{countryCode,isMobileView} } = useGlobalContext();
    return (
        <section className="section">
            <div className="container">
                <h2 className={`heading textCenter font26 text222 f500 mb12`}>Cities We Serve</h2>
                <p className={`subHeading textCenter mb46 font16`}>Our teams across India are unified by the love for interior design.</p>
                <div className={`${styles.cityServed}`}>
                    <div className={`${styles.mapSection} bgWhite`}>
                        {isMobileView ?
                            <Image
                                src={indiaMap}
                                alt='Interior Company Provides Interior Design Services Across India'
                                height={321}
                                width={310}
                            />
                            : <Image
                                src={indiaMap}
                                alt='Interior Company Provides Interior Design Services Across India'
                                height={540}
                                width={522}
                            />}
                    </div>
                    <div className={styles.enquiryForm+` bgPink`}>
                        <div className={styles.formBox+` bgWhite`}> 
                            <Lead ModuleType={ModuleType.INTERIOR} Title="Interior Design Services Executed to Perfection" ButtonText="Connect With Our Team " />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}